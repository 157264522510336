import { InjectionToken, Signal } from '@angular/core';
import { Data, Route } from '@angular/router';
import { ApiUrls } from '@salary/common/api/base-http-service';
import { ColumnDefinition } from '@salary/common/component';
import { EnumClass } from '@salary/common/dumb';
import { Lohnkontext } from '@salary/common/facade';
import { FieldConfig } from '@salary/common/formly';
import { OptionsHelper } from '@salary/common/utils';

export class RZProzessArea extends EnumClass {
  static readonly Finanzamt = 0;
  static readonly Sozialversicherung = 1;
  static readonly Intern = 2;
}

export const RZ_PROZESS_DEFINITION = new InjectionToken<RZProzessDefinition>(
  'RZ_PROZESS_DEFINITION',
);

export const RZ_PROZESSE_TOKEN = new InjectionToken<RZProzessDefinition[]>(
  'RZ_PROZESSE',
);

export const RZ_PROZESS_AREAS = OptionsHelper.getOptions(RZProzessArea);

export interface RZProzessDefinition {
  caption: string;
  iconName?: string;
  description?: string;
  route?: string;
  area: number;
  areaLabel?: string;
  apiUrl?: string;
  apiDomain?: ApiUrls;
  hide?: boolean;
  parameterFieldConfigs?: (lohnkontext?: Signal<Lohnkontext>) => FieldConfig[];
  detailComponent?: Route['loadComponent'];
  detailComponentData?: Data;
  endpointConfiguration?: { url: string; suffix: string };
  modifyColumns?: (columns: ColumnDefinition[]) => ColumnDefinition[];
}
